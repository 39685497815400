import Layout from './components/Layout';
import trainers from './dataset/trainers';
import pokemons from './dataset/pokemons';

import classes from './App.m.css';
import AppProvider from './contexts/AppProvider';

function App() {
    return (
        <AppProvider>
            <div className={classes.root}>
                <img
                    alt="battlefieldd"
                    src={require('./images/common/background.jpg').default}
                    className={classes['battlefield-bg']}
                />
                <div className={classes['battlefield-layout']}>
                    <Layout trainers={trainers} pokemons={pokemons} />
                </div>
            </div>
        </AppProvider>
    );
}

export default App;
