class PokemonModel {
    constructor({ id, group, level, avatar, name, type, firstEvolution }) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.group = group;
        this.level = level;
        this.avatar = avatar;
        this.firstEvolution = firstEvolution;
    }

    getPrev(evolutions) {
        return evolutions.find((pkm) => pkm.level === this.level - 1);
    }

    getNext(evolutions) {
        return evolutions.find((pkm) => pkm.level === this.level + 1);
    }
}

export default PokemonModel;