import Entiti from '../Entiti';
import PokemonHelper from '../../helpers/PokemonHelper';
import useApp from '../../hooks/useApp';

const { listOfSubstitutes } = PokemonHelper;

function Substitutes({ firstEvolutions, states: [[activeAttack]] }) {
    const {
        state: { activePokemon },
        actions: { upActive }
    } = useApp();

    return (
        <>
            {listOfSubstitutes(firstEvolutions, activePokemon).map((pokemon) => (
                <Entiti
                    isClickable={!activeAttack}
                    handleClick={() => upActive(pokemon)}
                    image={require('../../images/common/pokeball.png').default}
                    name={pokemon.name}
                />
            ))}
        </>
    );
}

export default Substitutes;
