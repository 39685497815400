import clsx from 'clsx';
import classes from './Entiti.m.css';

function Entiti({ isClickable, image, name, imgClass, handleClick }) {
    return (
        <button className={classes['root']} disabled={!isClickable} onClick={() => handleClick()}>
            <figure className={classes['figure']}>
                <img
                    alt=""
                    src={image}
                    className={clsx(classes['image'], imgClass)}
                />
                <figcaption className={classes['name']}>{name}</figcaption>
            </figure>
        </button>
    );
}

export default Entiti;
