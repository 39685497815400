import PokemonModel from '../models/PokemonModel';
import { ELECTRIK, FIRE, GRASS, WATER } from '../constants/PokemonConst';

const bulbasaur = {
    id: 0,
    group: 0,
    level: 0,
    avatar: 'https://img.pokemondb.net/sprites/home/normal/bulbasaur.png',
    name: 'Bulbizarre',
    type: GRASS,
};

const squirtle = {
    id: 3,
    group: 1,
    level: 0,
    avatar: 'https://img.pokemondb.net/sprites/home/normal/squirtle.png',
    name: 'Carapuce',
    type: WATER,
};

const charmander = {
    id: 6,
    group: 2,
    level: 0,
    avatar: 'https://img.pokemondb.net/sprites/home/normal/charmander.png',
    name: 'Salamèche',
    type: FIRE,
};

const pichu = {
    id: 9,
    group: 3,
    level: 0,
    avatar: 'https://img.pokemondb.net/sprites/home/normal/pichu.png',
    name: 'Pichu',
    type: ELECTRIK,
};

const pokemons = [
    {
        ...bulbasaur,
        firstEvolution: bulbasaur
    },
    {
        id: 1,
        group: 0,
        level: 1,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/ivysaur.png',
        name: 'Herbizarre',
        type: GRASS,
        firstEvolution: bulbasaur
    },
    {
        id: 2,
        group: 0,
        level: 2,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/venusaur.png',
        name: 'Florizarre',
        type: GRASS,
        firstEvolution: bulbasaur
    },
    {
        ...squirtle,
        firstEvolution: squirtle
    },
    {
        id: 4,
        group: 1,
        level: 1,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/wartortle.png',
        name: 'Carabaffe',
        type: WATER,
        firstEvolution: squirtle
    },
    {
        id: 5,
        group: 1,
        level: 2,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/blastoise.png',
        name: 'Tortank',
        type: WATER,
        firstEvolution: squirtle
    },
    {
        ...charmander,
        firstEvolution: charmander
    },
    {
        id: 7,
        group: 2,
        level: 1,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/charmeleon.png',
        name: 'Reptincel',
        type: FIRE,
        firstEvolution: charmander
    },
    {
        id: 8,
        group: 2,
        level: 2,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/charizard.png',
        name: 'Dracaufeu',
        type: FIRE,
        firstEvolution: charmander
    },
    {
        ...pichu,
        firstEvolution: pichu
    },
    {
        id: 10,
        group: 3,
        level: 1,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/pikachu.png',
        name: 'Pikachu',
        type: ELECTRIK,
        firstEvolution: pichu
    },
    {
        id: 11,
        group: 3,
        level: 2,
        avatar: 'https://img.pokemondb.net/sprites/home/normal/raichu.png',
        name: 'Raichu',
        type: ELECTRIK,
        firstEvolution: pichu
    },
];

export default pokemons.map((pkm) => new PokemonModel(pkm));
