import { useContext, useEffect, useState } from 'react';
import PokemonModel from '../models/PokemonModel';
import PokemonService from '../services/PokemonService';
import { AppContext } from '../contexts/AppProvider';
import { UP_ACTIVE_POKEMON } from '../contexts/AppReducer';

const useApp = () => {
    const {
        state: { activePokemon },
        dispatch,
    } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false);
    const [firstEvolutions, setFirstEvolutions] = useState([]);
    const [activeEvolutions, setActiveEvolutions] = useState([]);

    useEffect(() => {
        setActiveEvolutions(activePokemon ? PokemonService.query.findByGroup(activePokemon.group) : []);
    }, [activePokemon]);

    useEffect(() => {
        if (firstEvolutions.length === 0) {
            setFirstEvolutions(PokemonService.query.findByLevel(0));
        }
    }, [firstEvolutions]);

    const find = (id) => {
        setIsLoading(true);
        const pokemon = PokemonService.query.find(id);
        setIsLoading(false);
        return new PokemonModel(pokemon);
    };

    const upActive = (newActive) => {
        dispatch({ type: UP_ACTIVE_POKEMON, payload: newActive });
    };

    const findByLevel = (level) => {
        setIsLoading(true);
        const pokemons = PokemonService.query.findByLevel(level);
        setIsLoading(false);
        return pokemons.map((pkm) => new PokemonModel(pkm));
    };

    const findByGroup = (group) => {
        setIsLoading(true);
        const pokemons = PokemonService.query.findByGroup(group);
        setIsLoading(false);
        return pokemons.map((pkm) => new PokemonModel(pkm));
    };

    return {
        state: {
            isLoading,
            activePokemon,
            firstEvolutions,
            activeEvolutions,
        },
        actions: {
            find,
            upActive,
            findByLevel,
            findByGroup,
        },
    };
};

export default useApp;
