import { useState } from 'react';

import Entiti from './../Entiti';
import Attack from './../Attack';
import Actions from '../Actions';
import Evolutions from '../Evolutions';
import Substitutes from '../Substitutes';

import classes from './Layout.m.css';
import useApp from '../../hooks/useApp';

function Layout({ trainers, pokemons }) {
    const trainerLeft = trainers.at(0);

    const {
        state: { activePokemon, firstEvolutions },
    } = useApp();

    const activeAttackState = useState(false);

    const [activeAttack, setActiveAttack] = activeAttackState;

    return (
        <div className={classes['root']}>
            <div className={classes['evolutions']}>
                <Evolutions imgClass={classes['evolutions__img']} />
            </div>
            <div className={classes['battlefield']}>
                <div className={classes['trainer']}>
                    <Entiti
                        isClickable={false}
                        imgClass={classes['trainer__img']}
                        image={trainerLeft.avatar}
                        name={trainerLeft.pseudo}
                    />
                </div>
                <div className={classes['active']}>
                    {activePokemon && (
                        <Entiti
                            isClickable={!activeAttack}
                            handleClick={() => setActiveAttack(true)}
                            imgClass={classes['active__img']}
                            image={activePokemon.avatar}
                            name={activePokemon.name}
                        />
                    )}
                </div>
                <div className={classes['attack']}>
                    {activeAttack && activePokemon && <Attack type={activePokemon.type} states={[activeAttackState]} />}
                </div>
                <div className={classes['actions']}>
                    <Actions states={[activeAttackState]} />
                </div>
                <div className={classes['trainer']}></div>
            </div>
            <div className={classes['substitutes']}>
                <Substitutes states={[activeAttackState]} firstEvolutions={firstEvolutions} />
            </div>
        </div>
    );
}

export default Layout;
