import atkFire from '../images/attacks/fire.gif';
import atkWater from '../images/attacks/water.gif';
import atkGrass from '../images/attacks/grass.gif';
import atkElectrik from '../images/attacks/electrik.gif';
import { ELECTRIK, FIRE, GRASS, WATER } from '../constants/PokemonConst';

const AttackHelper = {
    getImg: (type) => {
        switch (type) {
            case FIRE:
                return atkFire;
            case WATER:
                return atkWater;
            case GRASS:
                return atkGrass;
            case ELECTRIK:
                return atkElectrik;
            default:
                return null;
        }
    },
};

export default AttackHelper;
