export const initialState = { 
    activePokemon: null 
};

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case UP_ACTIVE_POKEMON:
            return { ...state, activePokemon: payload };
        default:
            throw new Error();
    }
};

export const UP_ACTIVE_POKEMON = 'up-active-pkm';