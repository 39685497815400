import { useEffect, useRef } from 'react';
import AttackHelper from '../../helpers/AttackHelper';

import classes from './Attack.m.css';

function Attack({ type, states: [[activeAttack, setActiveAttack]] }) {
    const attackRef = useRef();

    useEffect(() => {
        if (activeAttack) {
            attackRef.current = setTimeout(() => setActiveAttack(false), 5000);
        }
        return () => clearTimeout(attackRef.current);
    }, [activeAttack, setActiveAttack]);

    return <img alt={`Attack ${type}`} src={AttackHelper.getImg(type)} className={classes['root']} />;
}

export default Attack;
