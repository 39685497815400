import useApp from '../../hooks/useApp';
import Entiti from '../Entiti';

function Evolutions({ imgClass }) {
    const {
        state: { activePokemon, activeEvolutions },
    } = useApp();
    return (
        <>
            {activeEvolutions.map(
                (pokemon) =>
                    pokemon !== activePokemon && (
                        <Entiti isClickable={false} imgClass={imgClass} image={pokemon.avatar} name={pokemon.name} />
                    )
            )}
        </>
    );
}

export default Evolutions;
