import clsx from 'clsx';
import useApp from './../../hooks/useApp';

import classes from './Actions.m.css';

function Actions({ states: [[activeAttack]] }) {
    const {
        state: { activePokemon, activeEvolutions },
        actions: { upActive }
    } = useApp();

    const prevPokemon = activePokemon?.getPrev(activeEvolutions);
    const nextPokemon = activePokemon?.getNext(activeEvolutions);

    const handleUpgrade = () => {
        if (nextPokemon) {
            upActive(nextPokemon);
        }
    };
    const handleDowngrade = () => {
        if (prevPokemon) {
            upActive(prevPokemon);
        }
    };
    const handleEscape = () => {
        upActive(null);
    };

    return (
        <div className={classes['root']}>
            <button
                className={clsx(classes['btn'], classes['upgrade'])}
                disabled={!nextPokemon || activeAttack}
                onClick={() => handleUpgrade()}>
                Évolution
                <span className={classes['btn__picto']}>+</span>
            </button>
            <button
                className={clsx(classes['btn'], classes['downgrade'])}
                disabled={!prevPokemon || activeAttack}
                onClick={() => handleDowngrade()}>
                Évolution
                <span className={classes['btn__picto']}>–</span>
            </button>
            <button
                className={clsx(classes['btn'], classes['escape'])}
                disabled={!activePokemon || activeAttack}
                onClick={() => handleEscape()}>
                Fuire
            </button>
        </div>
    );
}

export default Actions;
