import pokemons from "../dataset/pokemons";

const PokemonService = {
    query: {
        find: (id) => pokemons.find((pkm) => pkm.id === id),
        findByLevel: (level) => pokemons.filter((pkm) => pkm.level === level),
        findByGroup: (group) => pokemons.filter((pkm) => pkm.group === group).sort((a, b) => a.level - b.level),
    },
    commander: {},
};

export default PokemonService;